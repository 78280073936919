import React, { PropsWithChildren, useContext, useEffect, useState } from 'react'
import { User, onAuthStateChanged } from 'firebase/auth'
import { auth } from '@/src/services/FirebaseService'
import { LocalStorageService } from '@/src/services/LocalStorageService'

const FirebaseUserContext = React.createContext<{
  firebaseUser?: User | null
  isAdmin: boolean
  isLoading: boolean
}>({} as any)

export const useFirebaseUser = () => useContext(FirebaseUserContext)

export const FirebaseUserProvider = ({ children }: PropsWithChildren) => {
  const [firebaseAuthChecked, setFirebaseAuthChecked] = useState(false)
  const [firebaseUser, setFirebaseUser] = useState<User | null>(null)
  const [isAdmin, setAdmin] = useState<boolean>(false)

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const idTokenResult = await user?.getIdTokenResult()
        const locked = !!idTokenResult?.claims.locked
        if (locked) {
          LocalStorageService.saveAccountLock()
        } else {
          LocalStorageService.removeAccountLock()
        }
        setFirebaseUser(user)
        setAdmin(!!idTokenResult?.claims.admin)
      } else {
        LocalStorageService.removeAccountLock()
        setFirebaseUser(null)
      }
      setFirebaseAuthChecked(true)
    })
  }, [])

  return firebaseAuthChecked ? (
    <FirebaseUserContext.Provider
      value={{
        firebaseUser,
        isAdmin,
        isLoading: !firebaseAuthChecked,
      }}
    >
      {children}
    </FirebaseUserContext.Provider>
  ) : null
}
