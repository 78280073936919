import { GameCenterSize } from '@/src/graphql/generated/types'

export const DISPLAY_VERSION = 'Open Beta v3.0'

export const WALLET_ADDRESS_LENGTH = 42
export const WALLET_ADDRESS_PREFIX = '0x'

export const GC_MAX_ARCADE_COUNT: Record<GameCenterSize, number> = {
  LARGE: 64,
  MEDIUM: 16,
  SMALL: 4,
}

export const ExternalUrls = {
  tofuNftTop: 'https://tofunft.com/ja',
}

export const MAX_USERNAME_LENGTH = 20

export const CRAFTED_SURVEY_LINK =
  'https://docs.google.com/forms/d/e/1FAIpQLSdbEhMDaaNThnxza8CTTIFLszkH7FAKTAx8OzK0YVtq3Nyafw/viewform'

export const S3_URL = 'https://assets.akiverse.io/front-end'

// UTC
export const UTC_TIME_ZONE = 'Etc/GMT'

export const DISABLED_AMS = ['CURVE_BALL_3D']
