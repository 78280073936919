import { initializeApp } from 'firebase/app'
import { connectAuthEmulator, getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY || undefined,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN || undefined,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID || undefined,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET || undefined,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID || undefined,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID || undefined,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID || undefined,
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const newAuth = getAuth(app)

// デバッグ用エミュレータ指定
if (process.env.NEXT_PUBLIC_FIREBASE_EMULATOR_URL) {
  connectAuthEmulator(newAuth, process.env.NEXT_PUBLIC_FIREBASE_EMULATOR_URL)
}

export const auth = getAuth()
