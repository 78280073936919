import React, { PropsWithChildren } from 'react'
import NextHead from 'next/head'

type Props = PropsWithChildren<{
  title?: string
  description?: string
}>

export const Head: React.FC<Props> = (props) => {
  return (
    <NextHead>
      <title>{props.title ? `${props.title} | ` : ''}Akiverse</title>
    </NextHead>
  )
}
