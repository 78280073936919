import { S3_URL } from '../assets/constants'
import { CollectibleItemCategory, IconType } from '../graphql/generated/types'

export const S3URLService = {
  getArcadePartsURL: (category: string, subCategory: string) =>
    `${S3_URL}/arcadeparts/${category}/${subCategory}.png`,
  getArcadePartsJunkURL: (category: string, subCategory: string) =>
    `${S3_URL}/arcadeparts/${category}/${subCategory}_JUNK.png`,
  getIconURL: (iconType: IconType, subCategory: string) =>
    `${S3_URL}/collectible_items/${CollectibleItemCategory.Icon}/${iconType}/${subCategory}.png`,
  getFrameURL: (subCategory: string) => `${S3_URL}/collectible_items/FRAME/${subCategory}.png`,
  getCraftMovieURL: (subCategory: string) => `${S3_URL}/games/${subCategory}/craft.mp4`,
  getBoosterURL: (category: string, subCategory: string) => {
    if (['SPARK_TERAS_UP', 'GAME_SWAP'].includes(category)) {
      return `${S3_URL}/booster/${category}/${subCategory}.png`
    } else if (category === 'EASY_MODE') {
      if (!subCategory) {
        return `${S3_URL}/booster/${category}/ALL.png`
      }
      return `${S3_URL}/booster/${category}/${subCategory}.png`
    }
    return ''
  },
  getTournamentURL: (gameId: string) => `${S3_URL}/games/${gameId}/tournament.png`,
  getTournamentALLURL: () => `${S3_URL}/games/tournament_ALL.png`,
}
