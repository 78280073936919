import { z } from 'zod'

export const LOCAL_STORAGE_KEYS = {
  CRAFT_PARTS: 'CRAFT_PARTS',
  PRIVAY_POLICY_AGREEMENT_UNIXTIMESTAMP: 'PRIVAY_POLICY_AGREEMENT_UNIXTIMESTAMP',
  EMAIL_SIGNIN: 'EMAIL_SIGNIN',
  EMAIL_LOGIN: 'EMAIL_LOGIN',
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  ACCOUNT_LOCK: 'ACCOUNT_LOCK',
}

const craftPartsSchema = z
  .object({
    rom: z.string().optional(),
    accumulator: z.string().optional(),
    upperCabinet: z.string().optional(),
    lowerCabinet: z.string().optional(),
  })
  .optional()

export const LocalStorageService = {
  saveCraftPartIds: (parts: z.infer<typeof craftPartsSchema>) => {
    if (parts === undefined) {
      window.localStorage.removeItem(LOCAL_STORAGE_KEYS.CRAFT_PARTS)
    } else {
      const result = craftPartsSchema.safeParse(parts)
      if (result.success) {
        window.localStorage.setItem(LOCAL_STORAGE_KEYS.CRAFT_PARTS, JSON.stringify(result.data))
      } else {
        window.localStorage.removeItem(LOCAL_STORAGE_KEYS.CRAFT_PARTS)
      }
    }
  },
  loadCraftPartIds: (): z.infer<typeof craftPartsSchema> => {
    const localData = window.localStorage.getItem(LOCAL_STORAGE_KEYS.CRAFT_PARTS)

    if (!localData) return undefined

    const json = JSON.parse(localData)

    if (!json) return undefined

    const result = craftPartsSchema.safeParse(json)
    if (result.success) {
      return result.data
    } else {
      console.error(result.error)
      return undefined
    }
  },
  savePrivacyPolicyAgreementKey: (key: string) => {
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.PRIVAY_POLICY_AGREEMENT_UNIXTIMESTAMP, key)
  },
  loadPrivacyPolicyAgreementKey: () => {
    return window.localStorage.getItem(LOCAL_STORAGE_KEYS.PRIVAY_POLICY_AGREEMENT_UNIXTIMESTAMP)
  },
  removePrivacyPolicyAgreementKey: () => {
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.PRIVAY_POLICY_AGREEMENT_UNIXTIMESTAMP)
  },
  saveAccessToken: (token: string | undefined) => {
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, token || '')
  },
  loadAccessToken: () => {
    return window.localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || ''
  },
  removeAccessToken: () => {
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
  },
  saveRefreshToken: (token: string | undefined) => {
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, token || '')
  },
  loadRefreshToken: () => {
    return window.localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN) || ''
  },
  removeRefreshToken: () => {
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN)
  },
  saveEmailSignin: (token: string | undefined) => {
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.EMAIL_SIGNIN, token || '')
  },
  loadEmailSignin: () => {
    return window.localStorage.getItem(LOCAL_STORAGE_KEYS.EMAIL_SIGNIN) || ''
  },
  removeEmailSignin: () => {
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.EMAIL_SIGNIN)
  },
  saveEmailLogin: (token: string | undefined) => {
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.EMAIL_LOGIN, token || '')
  },
  loadEmailLogin: () => {
    return window.localStorage.getItem(LOCAL_STORAGE_KEYS.EMAIL_LOGIN) || ''
  },
  removeEmailLogin: () => {
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.EMAIL_LOGIN)
  },
  saveAccountLock: () => {
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.ACCOUNT_LOCK, String(true))
  },
  loadAccountLock: (): boolean => {
    return window.localStorage.getItem(LOCAL_STORAGE_KEYS.ACCOUNT_LOCK) === 'true'
  },
  removeAccountLock: () => {
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCOUNT_LOCK)
  },
}
