import { LocalStorageService } from './LocalStorageService'
import { auth } from './FirebaseService'
import { signOut } from 'firebase/auth'

export const AuthService = {
  isLoggedIn: () => {
    return (
      !!LocalStorageService.loadRefreshToken() ||
      (!!auth.currentUser && !LocalStorageService.loadAccountLock())
    )
  },
  logOut: async () => {
    await signOut(auth)
    await AuthService.removeTokens()
  },
  getAuthorizationToken: async () => {
    const accessToken = await auth.currentUser?.getIdToken()
    if (accessToken) {
      return `Bearer ${accessToken}`
    }

    return ''
  },
  getRefreshToken: () => {
    return LocalStorageService.loadRefreshToken()
  },
  removeTokens: async () => {
    LocalStorageService.removeAccessToken()
    LocalStorageService.removeRefreshToken()
  },
}
