export const PagePathService = {
  top: () => `/`,
  accountLocked: () => '/account-locked',
  welcome: () => '/welcome',
  login: () => '/login',
  signup: () => '/signup',
  mailSendComplete: () => '/mail-send-complete',
  gameCenterManager: () => `/game-centers`,
  gameCenterManagerStatus: () => `/game-centers/status`,
  gameCenterManagerShowcase: () => `/game-centers/showcase`,
  gameCenterManagerActivity: () => `/game-centers/activity`,
  gameCenterManagerInformation: () => `/game-centers/information`,
  gameCenterManagerOthers: () => `/game-centers/others`,
  gameCenterManagerShowCaseDetail: (gcId: string) => `/game-centers/showcase/${gcId}`,
  arcadeMachineManager: () => `/arcade-machines`,
  arcadeMachineManagerStatus: () => `/arcade-machines/status`,
  arcadeMachineManagerShowcase: () => `/arcade-machines/showcase`,
  arcadeMachineManagerCraft: () => `/arcade-machines/craft`,
  arcadeMachineManagerActivity: () => `/arcade-machines/activity`,
  arcadeMachineManagerInformation: () => `/arcade-machines/information`,
  arcadeMachineManagerOthers: () => `/arcade-machines/others`,
  arcadeMachineManagerShowCaseDetail: (amId: string) => `/arcade-machines/showcase/${amId}`,
  myPage: () => '/me',
  akiverseWorld: () => `/akiverse-world`,
  akiverseWorldTradeCenter: () => `/akiverse-world/trade-center`,
  playerManager: () => `/player-manager`,
  myGameCenters: () => '/me/game-centers',
  myArcadeMachines: () => '/me/arcade-machines',
  myArcadeParts: () => '/me/arcade-parts',
  myInWorldItems: () => '/me/in-world-items',
  myEdit: () => '/me/edit',

  adminBanner: () => '/admin/banner-manager',
  adminInterstitialBanner: () => '/admin/interstitial-banner-manager',
  tournamentBanner: () => '/admin/tournament-manager',
}
