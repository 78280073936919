import type { AppProps } from 'next/app'
import { ApolloProviderWrapper } from '../providers/ApolloProviderWrapper'
import { GlobalBackground } from '../parts/commons/GlobalBackground'
import '../../assets/i18n'
import { ProcessingContactsContextProvider } from '../states/ProcessingContactsContext'
import { LoginUserProvider } from '../states/LoginUser'
import { RecoilRoot } from 'recoil'
import { Head } from '../parts/commons/Head'
import GoogleTagManager, { GoogleTagManagerId } from '../parts/commons/GoogleTagManager'
import { googleTagManagerId } from '@/src/services/AnalyticsService'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { FirebaseUserProvider } from '../states/FirebaseUser'
import { GameProvider } from '../states/Games'

dayjs.extend(timezone)
dayjs.extend(utc)

export const App = ({ Component, pageProps }: AppProps) => {
  return (
    <>
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>

      <GoogleTagManager googleTagManagerId={googleTagManagerId as GoogleTagManagerId} />

      <RecoilRoot>
        <GlobalBackground>
          <ApolloProviderWrapper>
            <FirebaseUserProvider>
              <LoginUserProvider>
                <ProcessingContactsContextProvider>
                  <GameProvider>
                    <Component {...pageProps} />
                  </GameProvider>
                </ProcessingContactsContextProvider>
              </LoginUserProvider>
            </FirebaseUserProvider>
          </ApolloProviderWrapper>
        </GlobalBackground>
      </RecoilRoot>
    </>
  )
}
