import React, { CSSProperties, MouseEventHandler, PropsWithChildren } from 'react'

export const VStack: React.FC<
  PropsWithChildren<{
    className?: string | undefined
    onClick?: MouseEventHandler
    style?: CSSProperties | undefined
  }>
> = ({ children, className, style, onClick }) => {
  return (
    <div className={`relative flex flex-col ${className ?? ''}`} style={style} onClick={onClick}>
      {children}
    </div>
  )
}
