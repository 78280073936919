import React, { PropsWithChildren, useContext, useEffect, useRef } from 'react'
import { useLoginUser } from '../LoginUser'
import { CheckTransferNftItem, useCheckTransfersLazyQuery } from './query.generated'
import { AKVWei } from '@/src/services/AKVService'
import { AuthService } from '@/src/services/AuthService'

type Nft = { id: string; name: string }
type AKV = { amount: AKVWei }
// TODO: AKIR
type AKIR = { amount: string }

type ProcessingContacts = {
  depositingGameCenters: Array<Nft>
  withdrawingGameCenters: Array<Nft>
  depositingArcadeMachines: Array<Nft>
  withdrawingArcadeMachines: Array<Nft>
  depositingArcadeParts: Array<Nft>
  withdrawingArcadeParts: Array<Nft>
  depositingAKV: Array<AKV>
  withdrawingAKV: Array<AKV>
  depositingAKIR: Array<AKIR>
  withdrawingAKIR: Array<AKIR>
}

const ProcessingContactsContext = React.createContext<ProcessingContacts>({
  depositingGameCenters: [],
  withdrawingGameCenters: [],
  depositingArcadeMachines: [],
  withdrawingArcadeMachines: [],
  depositingArcadeParts: [],
  withdrawingArcadeParts: [],
  depositingAKV: [],
  withdrawingAKV: [],
  depositingAKIR: [],
  withdrawingAKIR: [],
})

export const ProcessingContactsContextProvider = ({ children }: PropsWithChildren) => {
  const loginUser = useLoginUser()
  const loginUserId = loginUser.loginUser?.id
  const walletAddress = loginUser.loginUser?.walletAddress ?? ''
  const loggedIn = !!loginUserId
  const [getProcessingContracts, { data, refetch }] = useCheckTransfersLazyQuery()

  useEffect(() => {
    AuthService.isLoggedIn() && getProcessingContracts()
  }, [loginUserId, walletAddress, getProcessingContracts])

  // useEffectでrefの中身を更新することでログアウト中はrefetchしない
  const callbackRef = useRef<() => void>(() => {})
  useEffect(() => {
    callbackRef.current = AuthService.isLoggedIn() ? refetch : () => {}
  }, [refetch, loginUser, loggedIn])

  useEffect(() => {
    const intervalId = setInterval(() => {
      callbackRef.current()
    }, 3000)
    return () => {
      clearInterval(intervalId)
    }
  }, [loggedIn])

  const createNft = (d: Array<CheckTransferNftItem> | undefined) => (d ? d.map((e) => ({ ...e })) ?? [] : [])
  const createAKV = (d: Array<any> | undefined) => (d ? d.map((e) => ({ amount: new AKVWei(e) })) ?? [] : [])
  // TODO: AKIR
  const createAKIR = (d: Array<any> | undefined) => (d ? d.map((e) => ({ amount: e })) ?? [] : [])

  const depositingGameCenters: Array<Nft> = createNft(data?.checkTransfers.nft.gameCenters.deposits)
  const withdrawingGameCenters: Array<Nft> = createNft(data?.checkTransfers.nft.gameCenters.withdraws)
  const depositingArcadeMachines: Array<Nft> = createNft(data?.checkTransfers.nft.arcadeMachines.deposits)
  const withdrawingArcadeMachines: Array<Nft> = createNft(data?.checkTransfers.nft.arcadeMachines.withdraws)
  const depositingArcadeParts: Array<Nft> = createNft(data?.checkTransfers.nft.arcadeParts.deposits)
  const withdrawingArcadeParts: Array<Nft> = createNft(data?.checkTransfers.nft.arcadeParts.withdraws)
  const depositingAKV: Array<AKV> = createAKV(data?.checkTransfers.ft.akv.deposits)
  const withdrawingAKV: Array<AKV> = createAKV(data?.checkTransfers.ft.akv.withdraws)
  const depositingAKIR: Array<AKIR> = createAKIR(data?.checkTransfers.ft.akir.deposits)
  const withdrawingAKIR: Array<AKIR> = createAKIR(data?.checkTransfers.ft.akir.withdraws)

  return (
    <ProcessingContactsContext.Provider
      value={{
        depositingGameCenters,
        withdrawingGameCenters,
        depositingArcadeMachines,
        withdrawingArcadeMachines,
        depositingArcadeParts,
        withdrawingArcadeParts,
        depositingAKV,
        withdrawingAKV,
        depositingAKIR,
        withdrawingAKIR,
      }}
    >
      {children}
    </ProcessingContactsContext.Provider>
  )
}

export const useProcessingContactsContext = () => useContext(ProcessingContactsContext)
