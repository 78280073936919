import { BigNumber, ethers } from 'ethers'

export class AKV {
  akv: string

  constructor(akv: string) {
    this.akv = akv
  }

  setAkv(akv: string): void {
    this.akv = akv
  }

  toWeiAKV(): BigNumber {
    return akvToWeiAKV(this.akv)
  }
}

export class AKVWei {
  akvWei: string

  constructor(akvWei: string) {
    const Decimal = require('decimal.js')
    const dec = new Decimal(akvWei)
    this.akvWei = dec.toFixed()
  }

  toAKV(): string {
    return weiToAKV(BigNumber.from(this.akvWei))
  }
}

export const akvToWeiAKV = (akv: string): BigNumber => {
  return ethers.utils.parseEther(akv)
}

export const weiToAKV = (wei: BigNumber): string => {
  return ethers.utils.formatEther(wei)
}

export const AKVService = {
  getDefaultCraftFee: () => 8,
  getDefaultExtractFee: () => 16,
  getDefaultDismantleFee: () => 40,
}

export const extractNumbers = (value: string) =>
  value.replace(/[０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xfee0)).replace(/[^0-9]/g, '')
