import React, { PropsWithChildren, useCallback, useContext, useEffect } from 'react'
import { useLoginUserLazyQuery } from './query.generated'
import { AnalyticsService } from '@/src/services/AnalyticsService'
import { IconType } from '@/src/graphql/generated/types'
import { PagePathService } from '@/src/services/PagePathService'
import { useRouter } from 'next/router'
import { S3URLService } from '@/src/services/S3URLService'
import { AKVWei } from '@/src/services/AKVService'
import { AuthService } from '@/src/services/AuthService'
import { useFirebaseUser } from '../FirebaseUser'

type LoginUser = {
  isAdmin: boolean
  akirBalance: number
  akvBalance: AKVWei
  terasBalance: number
  email: string
  id: string
  name: string
  walletAddress?: string | null
  iconType: IconType
  iconSubCategory: string
  frameSubCategory: string
  titleSubCategory: string
  userIconUrl: string
  userFrameUrl: string
  reload: () => Promise<void>
}

const LoginUserContext = React.createContext<{
  loginUser?: LoginUser
  reload: () => Promise<void>
  isLoading: boolean
}>({} as any)

export const useLoginUser = () => useContext(LoginUserContext)

export const LoginUserProvider = ({ children }: PropsWithChildren) => {
  const router = useRouter()
  const [getLoginUser, { data, refetch, loading, error }] = useLoginUserLazyQuery({})
  const firebase = useFirebaseUser()

  const reload = useCallback(async () => {
    if (AuthService.isLoggedIn()) {
      refetch()
    }
  }, [refetch])

  useEffect(() => {
    AuthService.isLoggedIn() && getLoginUser()
  }, [getLoginUser])

  useEffect(() => {
    if (data?.currentUser?.id) {
      AnalyticsService.setUserId(data.currentUser.id)
    }
  }, [data])

  useEffect(() => {
    const errorCode = error?.graphQLErrors?.[0]?.extensions?.code
    if (errorCode === 'USER_LOCKED') {
      router.push(PagePathService.accountLocked())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  return (
    <LoginUserContext.Provider
      value={{
        loginUser: error
          ? undefined
          : data?.currentUser
          ? {
              isAdmin: firebase.isAdmin,
              akirBalance: data.currentUser.akirBalance,
              akvBalance: new AKVWei(data.currentUser.akvBalance),
              terasBalance: data.currentUser.terasBalance,
              email: data.currentUser.email,
              id: data.currentUser.id,
              name: data.currentUser.name,
              walletAddress: data.currentUser.walletAddress,
              iconType: data.currentUser.iconType,
              iconSubCategory: data.currentUser.iconSubCategory,
              frameSubCategory: data.currentUser.frameSubCategory,
              titleSubCategory: data.currentUser.titleSubCategory,
              userIconUrl: S3URLService.getIconURL(
                data.currentUser.iconType,
                data.currentUser.iconSubCategory,
              ),
              userFrameUrl: S3URLService.getFrameURL(data.currentUser.frameSubCategory),
              reload,
            }
          : undefined,
        reload,
        isLoading: loading,
      }}
    >
      {children}
    </LoginUserContext.Provider>
  )
}
