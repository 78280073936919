import en from './strings/en.json'

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const resources = {
  en: {
    translation: en,
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
})

declare module 'react-i18next' {
  interface CustomTypeOptions {
    defaultNS: keyof typeof resources['en']
    resources: typeof resources['en']
  }
}
