import React, { PropsWithChildren } from 'react'
import { Fixed } from '../bases/Fixed'
import { VStack } from '../bases/VStack'
import bgLightGreen from '../../../../public/images/bgLightGreen.png'
import bgLightPurple from '../../../../public/images/bgLightPurple.png'
import Image from 'next/image'

export const GlobalBackground: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <main className="flex min-h-screen min-w-full justify-center bg-black p-0">
      <div className="min-h-screen w-full">
        <VStack className={`h-full min-h-screen w-full bg-black bg-global-background-grid bg-repeat`}>
          <Fixed className="top-0 left-0 z-0 aspect-[2048/2160] h-full">
            <Image src={bgLightGreen} layout="fill" alt="bgLightGreen" />
          </Fixed>
          <Fixed className="top-0 right-0 z-0 aspect-[1680/2160] h-full">
            <Image src={bgLightPurple} layout="fill" alt="bgLightPurple" />
          </Fixed>
          {children}
        </VStack>
      </div>
    </main>
  )
}
